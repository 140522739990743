
import { defineComponent, reactive, watch } from 'vue'
import Standby from './Standby.vue'
import Box from './Box.vue'
import useIframeControl from '@/hooks/iframe'
import useStore from '@/hooks/store'

type State = {
  [key: string]: string
}

interface SetupReturn {
  state: State,
  handleOpenBox(): void,
  handleCloseBox(): void
}

export default defineComponent({
  components: {
    Standby,
    Box
  },
  setup (): SetupReturn {
    const iframe = useIframeControl()
    const store = useStore()

    const state = reactive<State>({
      component: 'Standby'
    })

    watch(() => store.currentComponent, () => {
      iframe.updateCoreValuesOnStore()
    })

    function handleOpenBox (): void {
      iframe.notifyOpen()
      state.component = 'Box'
    }

    function handleCloseBox (): void {
      iframe.notifyClose()
      state.component = 'Standby'
    }

    return {
      state,
      handleOpenBox,
      handleCloseBox
    }
  }
})
