
import { defineComponent } from 'vue'
import Icon from '../Icon/index.vue'
import palette from '../../../palette.js'
import { resetStore } from '@/store'

interface SetupReturn {
  goBack(): void;
  palette: Record<string, string>;
}

export default defineComponent({
  components: { Icon },
  setup (): SetupReturn {
    function goBack (): void {
      resetStore()
    }
    return {
      goBack,
      palette: palette.brand
    }
  }

})
