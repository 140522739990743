
import { defineComponent, computed, ComputedRef, SetupContext } from 'vue'
import { brand } from '../../../palette'
import colors from 'tailwindcss/colors'
import useStore from '@/hooks/store'
import Icon from '../../components/Icon/index.vue'
import Wizard from '../../components/Wizard/index.vue'
import useNavigation, { Navigation } from '@/hooks/navigation'

interface SetupReturn {
  emit: SetupContext['emit'];
  back: Navigation['back'];
  canGoBack: ComputedRef<boolean>;
  label: ComputedRef<string>;
  canShowAdditionalControlAndInfo: ComputedRef<boolean>;
  brandColors: Record<string, string>;
  colors: Record<string, string>;
}

export default defineComponent({
  components: { Icon, Wizard },
  emits: ['close-box'],
  setup (_, { emit }: SetupContext): SetupReturn {
    const store = useStore()

    const { back } = useNavigation()

    const label = computed<string>(() => {
      if (store.feedbackType === 'ISSUE') {
        return 'Reporte um problema'
      }
      if (store.feedbackType === 'IDEA') {
        return 'Nos fale a sua ideia'
      }
      if (store.feedbackType === 'OTHER') {
        return 'Abra sua mente'
      }
      return 'O que você tem em mente?'
    })
    const canGoBack = computed<boolean>(() => {
      return store.currentComponent === 'SelectFeedbackType'
    })
    const canShowAdditionalControlAndInfo = computed<boolean>(() => {
      return store.currentComponent !== 'Success' && store.currentComponent !== 'Error'
    })
    return {
      colors,
      brandColors: brand,
      emit,
      label,
      back,
      canGoBack,
      canShowAdditionalControlAndInfo
    }
  }
})
